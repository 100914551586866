<template>
  <div>
    <div class="sm:flex sm:items-center sm:justify-between mb-20 px-16 sm:px-0">
      <h1 class="font-medium text-lg sm:mb-0">Produkter</h1>
    </div>

    <products-filter
      v-if="isAdmin"
      :users="usersList"
      class="mb-20"
      :value="userFilter"
      :disabled="userFilterLoading"
      @input="onUserSelect"
    />

    <products-list :products-list="productsList" />

    <div class="mt-24" style="display:none">
      <div class="flex items-center">
        <el-status status="inApproval" />
        <p class="ml-4">Status explainer</p>
      </div>
      <div class="flex items-center mt-2">
        <el-status status="published" />
        <p class="ml-4">Status explainer</p>
      </div>
      <div class="flex items-center mt-2">
        <el-status status="archived" />
        <p class="ml-4">Status explainer</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { enums } from '@/core/enums'
import ProductsService from '@/services/products.service'
import UserService from '@/services/user.service'

const ProductsList = () => import('@/components/products/ProductsList')
const ElStatus = () => import('@/components/shared/ELStatus')
const ProductsFilter = () => import('@/components/products/ProductsFilter')

export default {
  name: 'ProductsdPage',

  components: { ProductsList, ElStatus, ProductsFilter },

  data () {
    return {
      buttonLoading: false,
      productsList: [],
      usersList: [],
      userFilter: '',
      userFilterLoading: false
    }
  },

  computed: {
    ...mapState({
      userData: state => state.user.userData
    }),

    isAdmin () {
      return this.userData && (this.userData.role === enums.ROLES.ADMIN)
    }
  },

  created () {
    this.firstload()
  },

  methods: {
    ...mapActions(['loading']),

    firstload () {
      const promiseArr = [ProductsService.getProductsList()]

      if (this.isAdmin) {
        promiseArr.push(UserService.getAdminUsers({ role: 'advertiser' }))
      }

      Promise.all(promiseArr)
        .then(result => {
          this.productsList = result[0]

          if (this.isAdmin) this.usersList = result[1]

          this.loading(false)
        })
    },

    onUserSelect (value) {
      this.userFilter = value
      this.userFilterLoading = true
      ProductsService.getProductsList({ advertiser_id: value })
        .then(res => {
          this.productsList = res
          this.userFilterLoading = false
        })
    }
  }
}
</script>
